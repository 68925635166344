<script>
import Layout from "../../layouts/main";
import ChapterLayout from '../../layouts/chapterLayout';
import userService from '../../../helpers/userService';
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Lecture Info",
  },
  name: "Lecture-details",
  components:{
    Layout,
    ChapterLayout
  }, 
  async created(){
    this.role = userService.methods.userRole();
    await this.$store.dispatch('chapter/getChapterInfo',this.$route.params.chapter).then(()=>{
        this.profileData = this.$store.getters['chapter/getChapter'];
    }).catch(err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('chapter/getChapterCounts',this.$route.params.chapter).then(()=>{
        this.count = this.$store.getters['chapter/getChapterCounts'];
    }).catch(err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  data() {
    return {
      profileData:{},
      count:{},
      role: null,
    };
  },
//   filters:{
//     formatDate(value){
//       if(value){
//         return moment(String(value)).format('hh:mm A DD/MM/YYYY')
//       }
//     }
//   }
};
</script>

<template>
  <Layout>
    <ChapterLayout :chapter="profileData">
        <div>
            <h5 class="mb-3 text-uppercase bg-light p-2">
                <i class="mdi mdi-account-circle mr-1 "></i> Basic Info
            </h5>
            <table class="table table-borderless table-sm">
            <tbody class="row">
                <tr class="col-md-6">
                <th scope="row">Lecture Name:</th>
                <td v-if="profileData.name ">
                    {{ profileData.name }}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Stage:</th>
                <td v-if="profileData.educational_year">
                    {{ profileData.educational_year.number + ' - ' + profileData.educational_year.description}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Description:</th>
                <td v-if="profileData.description">
                    {{ profileData.description}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Price:</th>
                <td v-if="profileData.price != null">
                    {{ profileData.price}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Online Students:</th>
                <td v-if="count.online_count != null">
                    {{ count.online_count}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Physical Students:</th>
                <td v-if="count.physical_count != null">
                    {{ count.physical_count}}
                </td>
                </tr>
            </tbody>
            </table>
            <b-container class="bv-example-row bv-example-row-flex-cols">
            <b-row>
                <b-col class="col-md-2 offset-md-10 offset-0">
                <router-link :to="'/chapters/'+this.$route.params.chapter+'/edit'" v-if="this.role == 0">
                    <b-button variant="outline-primary" pill>
                    <i class="fas fa-pen mr-1"></i>
                    Edit 
                    </b-button>
                </router-link>
                </b-col>
            </b-row>
            </b-container>
            
        </div>
    </ChapterLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
